export const environment = {
  production: true,
  errorMessage: "Unfortunately, this service will be unavailable between 7pm - 10pm 4th December while we carry out essential maintenance. Please revisit this link on or after 10pm on 4th December in order to submit the details we require, there is no need to contact us separately. Thank you very much for your patience and we look forward to seeing you soon.",
  isOffline: false,
  UserValidationLookupUrl:"https://hfdigital-apim.azure-api.net/heart-liability/UserIdentification",
  GetQuestionsLookupUrl:"https://hfdigital-apim.azure-api.net/heart-liability/GetQuestions",
  SubmitQuestionFormLookupUrl:"https://hfdigital-apim.azure-api.net/heart-liability/SubmitTriage",
  maxFileSizeBytes:"10485760",
  maxFileCount:"5",
  allowedFileExtensions:".pdf,.jpeg,.jpg,.png",
  sessionLengthSec:"3600",
  captchaTimeoutMsec:"10000",
  captchaKey:"6LezgpgjAAAAAKqjdMg7z0Obb6hxp8qOTqZPGUEE",
  ApiSubscriptionKey:"9c014461fe3e4317a6929bbef1e60008",
  ValidatedEnvironment: 3,
  TextBoxCharactersLimit: 250,
  BigTextBoxCharactersLimit: 500
};
